import React, { useEffect, useState } from 'react';
import { dateFormated, isValueEmpty } from './../Components/Utility/index';
import { Tooltip } from 'react-tooltip';
import { deleteVacation } from './Delete';
import { Tab, Tabs } from 'react-bootstrap';

const Vacation = ({ dataVacationByNik, isAdmin }) => {
  const [vacationByYear, setVacationByYear] = useState({});
  const [yearList, setYearList] = useState([]);

  useEffect(() => {
    const vacationData = dataVacationByNik.reduce((acc, item) => {
      const year = new Date(item.date_vacation).getFullYear();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(item);
      return acc;
    }, {});

    const currentYear = new Date().getFullYear();
    if (!vacationData[currentYear]) {
      vacationData[currentYear] = [];
    }

    setVacationByYear(vacationData);
    setYearList(Object.keys(vacationData).sort((a, b) => b - a));
  }, [dataVacationByNik]);

  const calculateAnnualTotal = (year) => {
    const annualVacation = 12;
    const totalUsed = vacationByYear[year]?.filter(item =>
      item.category === 'cut' || item.category === 'cub'
    ).reduce((acc, item) => acc + item.total, 0) || 0;

    return { totalUsed, remaining: annualVacation - totalUsed };
  };

  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='container mt-2 card mb-2 animate__animated animate__fadeInDown'>
      <div className='title-h1 text-center fs-1'>Masa Cuti</div>
      <Tabs defaultActiveKey={yearList[0]} className='mb-3'>
        {yearList.map((year) => (
          <Tab eventKey={year} title={year} key={year}>
            {vacationByYear[year]?.length > 0 ? (
              <table className='table table-bordered border-dark table-hover mt-1 mb-4'>
                <thead className='table-success table-bordered border-dark'>
                  <tr className='text-center fs-5'>
                    <th>No.</th>
                    <th>No Cuti</th>
                    <th>Tanggal</th>
                    <th>Kembali Kerja</th>
                    <th>Lama Cuti</th>
                    <th>Keterangan</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {vacationByYear[year].map((item, i) => {
                    const { id_letter } = item;
                    return (
                      <tr key={i} className='text-center'>
                        <td className='fw-bold'>{i + 1}</td>
                        <td>
                          {item.no_letter}
                          {!isValueEmpty(item.filename) && (
                            <>
                              <i
                                className="bi bi-download text-primary ms-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  downloadFile(`${process.env.REACT_APP_URL}/vacation/${item.filename}`)}
                                data-tooltip-id='download-file-tooltip'
                                data-tooltip-content='download file'
                              ></i>
                              <Tooltip id='download-file-tooltip' place='bottom' />
                            </>
                          )}
                        </td>
                        <td>{dateFormated(item.date_vacation)}</td>
                        <td>{dateFormated(item.date_work)}</td>
                        <td>{item.total} Hari</td>
                        <td className='text-start'>{item.info}</td>
                        <td>
                          {isAdmin && (
                            <i
                              className="bi bi-trash text-danger"
                              style={{ cursor: 'pointer' }}
                              onClick={() => deleteVacation({ id_letter })}
                              data-tooltip-id='delete-vacation-tooltip'
                              data-tooltip-content='hapus cuti'
                            ></i>
                          )}
                          <Tooltip id='delete-vacation-tooltip' place='bottom' />
                        </td>
                      </tr>
                    );
                  })}
                  <tr className='text-center fw-bold fs-4'>
                    <td colSpan={4}>Total Cuti Tahunan</td>
                    <td colSpan={3}>
                      {calculateAnnualTotal(year).totalUsed} Hari
                      <span className='text-primary ms-2'>
                        (sisa {calculateAnnualTotal(year).remaining} hari)
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <p className='text-center title-h1 fs-3 m-5'>Belum ada Cuti di tahun {year} (Jatah Cuti Tahunan 12 hari)</p>
            )}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default Vacation;
