import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavigationBar from '../../Components/Utility/Navbar';
import logoSN from '../../Assets/Logo/SINET.png';
import { Tabs, Tab } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import * as XLSX from 'xlsx';

const RekapVacationSN = ({ title }) => {
  const location = useLocation();
  const dataVacation = location.state.dataVacation;
  const dataEmployees = location.state.dataEmployees;

  const dataEmployee = dataEmployees.filter(item =>
    item.unit.toLowerCase() === 'sinet' &&
    item.status.toLowerCase() === 'aktif' &&
    (item.ikatan_kerja.toLowerCase() === 'kontrak' || item.ikatan_kerja.toLowerCase() === 'tetap')
  );

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des'];
  const dataLabel = ['No.', 'NIK', 'Nama', ...months, 'Total', 'Sisa'];
  const headers = ['NIK', 'Nama', 'Jabatan', ...months, 'Total', 'Sisa'];

  const [vacationByYear, setVacationByYear] = useState({});
  const [yearList, setYearList] = useState([]);
  const [selectYear, setSelectYear] = useState(yearList[0])

  useEffect(() => {
    const groupedData = dataVacation.reduce((acc, vacation) => {
      const year = new Date(vacation.date_vacation).getFullYear();
      if (!acc[year]) acc[year] = [];
      acc[year].push(vacation);
      return acc;
    }, {});

    setVacationByYear(groupedData);
    setYearList(Object.keys(groupedData).sort((a, b) => b - a));
  }, [dataVacation]);

  useEffect(() => {
    if (yearList.length > 0) {
      setSelectYear(yearList[0]);
    }
  }, [yearList])

  const groupVacationByEmployeeAndMonth = (nik, month, year) => {
    const vacationData = vacationByYear[year]?.filter(vacation =>
      vacation.nik === nik &&
      new Date(vacation.date_vacation).getMonth() === month &&
      (vacation.category === 'cut' || vacation.category === 'cub')
    ) || [];
    return vacationData.reduce((total, vacation) => total + vacation.total, 0);
  };

  const data = dataEmployee.map((data) => {
    const monthlyVacation = months.map((_, monthIndex) => groupVacationByEmployeeAndMonth(data.nik, monthIndex, selectYear));
    const totalVacation = monthlyVacation.reduce((total, monthTotal) => total + monthTotal, 0);
    const remainingVacation = (12 - totalVacation) + " Hari";
    if (!totalVacation) return null;
    return [data.nik, data.nama_lengkap, data.jabatan, ...monthlyVacation, totalVacation, remainingVacation]
  }).filter(Boolean)

  const handleExportData = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

    XLSX.utils.book_append_sheet(wb, ws, "rekap cuti tahunan");
    XLSX.writeFile(wb, `Rekap Cuti Tahunan SN Tahun ${selectYear}.xlsx`)
  }

  return (
    <>
      <NavigationBar logoSN={logoSN} title={title} />
      <div className='m-2 animate__animated animate__fadeInDown'>
        <i className="bi bi-file-earmark-arrow-down position-fixed end-0 me-3 fs-4" style={{ cursor: "pointer" }}
          data-tooltip-id='export-vacation-tooltip'
          data-tooltip-content='export ke excel'
          onClick={handleExportData}
        ></i>
        <Tooltip id='export-vacation-tooltip' place='bottom' />
        {yearList.length > 0 ? (
          <Tabs defaultActiveKey={yearList[0]}
            className='mt-4'
            onSelect={(year) => {
              setSelectYear(year)
            }}
          >
            {yearList.map(year => (
              <Tab eventKey={year} title={year} key={year}>
                <div className='mt-3'>
                  <table className='table table-bordered border-dark'>
                    <thead className='table-success border-dark sticky-header'>
                      <tr>
                        {dataLabel.map((label, i) => (
                          <th key={i} className='text-center fw-bold fs-5'>{label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dataEmployee.filter(employee => {
                        const monthlyVacations = months.map((_, monthIndex) =>
                          groupVacationByEmployeeAndMonth(employee.nik, monthIndex, year)
                        );
                        const totalVacation = monthlyVacations.reduce((total, monthTotal) => total + monthTotal, 0);
                        return totalVacation > 0;
                      }).map((employee, j) => {
                        const monthlyVacations = months.map((_, monthIndex) =>
                          groupVacationByEmployeeAndMonth(employee.nik, monthIndex, year)
                        );
                        const totalVacation = monthlyVacations.reduce((total, monthTotal) => total + monthTotal, 0);

                        return (
                          <tr key={j} className='text-center'>
                            <td>{j + 1}</td>
                            <td>{employee.nik}</td>
                            <td className='text-start'>{employee.nama_lengkap}</td>
                            {monthlyVacations.map((total, k) => (
                              <td key={k}>{(total === 0 ? '-' : total)}</td>
                            ))}
                            <td>{(totalVacation === 0 ? '-' : totalVacation)}</td>
                            <td className='text-danger'>{12 - totalVacation} Hari</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Tab>
            ))}
          </Tabs>
        ) : (
          <div className='alert alert-info text-center mt-4 text-dark fw-bold fs-1'>
            Belum ada yg cuti 😊
          </div>
        )}
      </div>
    </>
  );
};

export default RekapVacationSN;
