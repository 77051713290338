import React, { useEffect, useState } from "react"
import axios from 'axios'
import logoAE from '../Assets/Logo/Aditama.png'
import logoBK from '../Assets/Logo/BK.png'
import logoSN from '../Assets/Logo/SINET.png'
import NavigationBar from "../Components/Utility/Navbar"
import { useNavigate } from 'react-router-dom'
import { Tooltip } from "react-tooltip"
import { countAge, generationCategory, Loaders, todayDate } from "../Components/Utility"
import { jwtDecode } from "jwt-decode"
import * as XLSX from "xlsx";

const DataEmployee = () => {

  const [dataEmployees, setDataEmployees] = useState([])
  const [dataVacation, setDataVacation] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [isAdmin, setIsAdmin] = useState('')
  const [isDirAE, setIsDirAE] = useState('')
  const [isDirBK, setIsDirBK] = useState('')
  const [isDirSN, setIsDirSN] = useState('')
  const [isPmAE, setIsPmAE] = useState('')
  const [isOmAE, setIsOmAE] = useState('')
  const [isIspLog, setIsIspLog] = useState('')
  const [isIsSmBK, setIsSmBK] = useState('')
  const [isIsKorSN, setIsKorSN] = useState('')
  const [username, setUsername] = useState('')
  const [master, setMaster] = useState('')
  const [isOn, setIsOn] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const shuffleArray = (array) => {
    let shuffledArray = [...array]
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  useEffect(() => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_URL}/users/token`, { withCredentials: true })
        .then((res) => {
          const decoded = jwtDecode(res.data.token)
          setUsername(decoded.username)
          setMaster(decoded.username === 'Aliansyah')
          setIsAdmin(decoded.status.toLowerCase() === 'admin')
          setIsDirAE(decoded.status.toLowerCase() === 'direktur ae')
          setIsDirBK(decoded.status.toLowerCase() === 'direktur bk')
          setIsDirSN(decoded.status.toLowerCase() === 'direktur sn')
          setIsPmAE(decoded.status.toLowerCase() === 'pm ae')
          setIsOmAE(decoded.status.toLowerCase() === 'om ae')
          setIsIspLog(decoded.status.toLowerCase() === 'inspektur logistik')
          setIsSmBK(decoded.status.toLowerCase() === 'sm bk')
          setIsKorSN(decoded.status.toLowerCase() === 'koordinator sn')
        })
        .catch((err) => {
          console.log('err', err.message)
          alert('Anda Belum login atau sesi anda habis , Login dulu ...!')
          window.location.replace('/login')
        }),
      axios.get(`${process.env.REACT_APP_URL}/employees`).then((result) => {
        const dataEmployee = result.data.employee
        const shuffleData = shuffleArray(dataEmployee)
        setDataEmployees(shuffleData)
      }),
      axios.get(`${process.env.REACT_APP_URL}/vacation`).then((res) => {
        setDataVacation(res.data.dataVacation)
      })
    ]).then(() => {
      setLoading(false)
    }).catch((err) => {
      console.log('Error fetching data:', err.message)
    })
  }, [])

  const suggestionSearch = dataEmployees.flatMap((employees) => [
    employees.nama_lengkap, employees.generation_category
  ])

  const handleRowClick = (nik) => {
    navigate(`/detailEmployee/${nik}`, { state: { from: '/dataEmployee', isAdmin: isAdmin, master: master, username: username } })
  }

  const getEmployeeVacations = (nik) => {
    return dataVacation.filter(vacation => vacation.nik === nik && (vacation.category === 'cut' || vacation.category === 'cub'))
  }

  const headers = [
    "NIK",
    "Unit",
    "Status",
    "Nama Lengkap",
    "Jabatan",
    "Divisi",
    "Level",
    "Lokasi Jerja",
    "Ikatan Kerja",
    "NO KTP",
    "Alamat",
    "Tempat Lahir",
    "Tanggal Lahir",
    "Jenis Kelamin",
    "Agama",
    "Pendidikan",
    "Status Pernikahan",
    "Status Pajak",
    "NO HP",
    "NO BPJS KESEHATAN",
    "NO BPJS KETENAGAKERJAAN"
  ]

  const filterAktifEmployee = dataEmployees.filter(data => (
    isOn || data.status.toLowerCase() === 'aktif'
  ))

  const filterDataEmployee = filterAktifEmployee.map(data => [
    data.nik,
    data.unit,
    data.status,
    data.nama_lengkap,
    data.jabatan,
    data.divisi,
    data.level,
    data.lokasi_kerja,
    data.ikatan_kerja,
    data.ktp,
    data.alamat,
    data.tempat_lahir,
    data.tanggal_lahir,
    data.jenis_kelamin,
    data.agama,
    data.pendidikan,
    data.status_pernikahan,
    data.status_pajak,
    data.no_hp,
    data.no_bpjs_kesehatan,
    data.no_bpjs_ketenagakerjaan
  ])


  const handleExportData = () => {
    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet([headers, ...filterDataEmployee])

    XLSX.utils.book_append_sheet(wb, ws, "Data Karyawan")
    XLSX.writeFile(wb, `Data Karyawan ${isOn ? 'Aktif & Tidak Aktif' : 'Aktif'} BKG ${todayDate}.xlsx`)
  }

  return (
    <>
      <NavigationBar searchValue={searchValue} setSearchValue={setSearchValue} suggestionSearch={suggestionSearch} isOn={isOn} setIsOn={setIsOn} username={username} isAdmin={isAdmin} master={master} />
      {loading ? (
        <Loaders />
      ) : (
        <div className="container mt-3">
          <i className="bi bi-arrow-left"
            onClick={() => navigate('/dashboard')}
            data-tooltip-id="back-dashboard-tooltip" data-tooltip-content="kembali ke Dashboard"
          ></i>
          <i className="bi bi-file-earmark-arrow-down fs-1 btn btn-sm"
            style={{ position: "fixed", top: "60px", right: "30px" }}
            data-tooltip-id='export-dataEmployee-tooltip'
            data-tooltip-content="Export Ke Excel"
            onClick={handleExportData}
          ></i>
          <Tooltip id="export-dataEmployee-tooltip" />
          <Tooltip id="back-dashboard-tooltip" />
          <div className="row d-flex justify-content-center gap-3">
            {dataEmployees.filter((employees) => {
              return (
                (isOn || employees.status.toLowerCase() === 'aktif') && (
                  employees.nama_lengkap && employees.nama_lengkap.toLowerCase().includes(searchValue.toLowerCase()))
              )
            }).length === 0 ? (
              <div className='fs-1 text-success text-center vh-100 d-flex justify-content-center align-items-center'>Tidak ada nama karyawan anda cari , coba lagi 😊</div>
            ) : (
              dataEmployees.filter((employees) => {
                if (isDirAE && employees.unit.toLowerCase() !== 'aditama energi') return false;
                if (isDirBK && employees.unit.toLowerCase() !== 'bara kumala') return false;
                if (isDirSN && employees.unit.toLowerCase() !== 'sinet') return false;
                if (isOmAE && !(
                  (employees.divisi.toLowerCase() === 'engineering') ||
                  (employees.unit.toLowerCase() === 'aditama energi' && employees.lokasi_kerja.toLowerCase() === 'site' &&
                    ['explorasi', 'perencanaan'].includes(employees.divisi.toLowerCase()))
                )) return false;
                if (isPmAE && !(
                  (employees.divisi.toLowerCase() === 'produksi') ||
                  (employees.unit.toLowerCase() === 'aditama energi' &&
                    employees.lokasi_kerja.toLowerCase() === 'site' &&
                    ['umum', 'mine road', 'safety'].includes(employees.divisi.toLowerCase())) ||
                  (employees.unit.toLowerCase() === 'aditama energi' && employees.nik === 2212063)
                )) return false;

                if (isIspLog && !(
                  (employees.divisi.toLowerCase() === 'logistik') || ([2217314, 1112043, 2316232].includes(employees.nik))
                )) return false;
                if (isIsSmBK && (employees.unit.toLowerCase() !== 'bara kumala' || employees.lokasi_kerja.toLowerCase() !== 'site')) return false;
                if (isIsKorSN && (employees.unit.toLowerCase() !== 'sinet' || employees.lokasi_kerja.toLowerCase() !== 'site')) return false;


                return (
                  (isOn || employees.status.toLowerCase() === 'aktif') && (
                    employees.nama_lengkap && employees.nama_lengkap.toLowerCase().includes(searchValue.toLowerCase()))
                )
              }).map((employees, i) => {
                const { nik, unit, nama_lengkap, jabatan, ikatan_kerja, tanggal_lahir, no_hp, domisili, foto_karyawan, awal_pkwt_1 } = employees;

                const companyLogos = {
                  'ADITAMA ENERGI': logoAE,
                  'BARA KUMALA': logoBK,
                  'SINET': logoSN
                }

                const logo = companyLogos[unit] || '';
                const imageEmployee = `${process.env.REACT_APP_URL}/employees/${foto_karyawan}`

                const employeeVacations = getEmployeeVacations(nik)
                const workDayDuration = (new Date() - new Date(awal_pkwt_1)) / (1000 * 60 * 60 * 24);
                const currentYear = new Date().getFullYear();
                const dayVacation = workDayDuration >= 365 ? 12 : 0;

                const totalEmployeeYear = employeeVacations.reduce((acc, item) => {
                  const vacationYear = new Date(item.date_vacation).getFullYear();
                  if (vacationYear === currentYear && (item.category === 'cut' || item.category === 'cub')) {
                    return acc + item.total
                  }
                  return acc
                }, 0)

                const remainingDayVacation = ikatan_kerja === 'HARIAN' ? "Tidak ada Cuti" : dayVacation > 0 ? (`Sisa Cuti : ${dayVacation - totalEmployeeYear} hari`) : "Belum dapat hak cuti";

                return (
                  <div key={i}
                    onClick={() => handleRowClick(nik)}
                    className="w-25 border border-black rounded shadow d-flex justify-content-center flex-column align-items-center p-2 mb-3 text-center animate__animated animate__fadeInDown btn" >
                    <Tooltip id="delete-employee-tooltip" className="bg-danger" place="bottom" />
                    <img src={logo} alt="logo" className="logo-dashboard" />
                    <img src={imageEmployee} alt='foto karyawan' className="foto-dashboard" />
                    <div>{nik}</div>
                    <div className="fw-bold">{nama_lengkap}</div>
                    <div>{jabatan}</div>
                    <div>{ikatan_kerja}</div>
                    <div>{countAge(tanggal_lahir)} Tahun (<span className="text-secondary me-1">{generationCategory(tanggal_lahir)}</span>)</div>
                    <div>{no_hp}</div>
                    <div>Domisili : {domisili}</div>
                    <div>{remainingDayVacation}</div>
                  </div>
                )
              })
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default DataEmployee
