import React, { useState } from 'react';
import NavigationBar from '../../../Components/Utility/Navbar';
import logoSN from '../../../Assets/Logo/SINET.png';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import HOK from './HOK';
import JOK from './JOK';
import Late from './Late';
import Absent from './Absent';
import Permission from './Permission';
import Sick from './Sick';
import GoHome from './GoHome';
import KPI from './KPI';
import DR from './DR';
import Evaluation from './Evaluation';
import { Tooltip } from 'react-tooltip';
import * as XLSX from 'xlsx';

const RekapPerformanceSN = ({ title }) => {
  const location = useLocation();
  const dataPerformance = location.state.dataPerformance;
  const dataEmployees = location.state.dataEmployees;
  const [showStatus, setShowStatus] = useState(false);

  const dataEmployeeFiltered = dataEmployees.filter(
    (item) =>
      item.unit.toLowerCase() === 'sinet' &&
      (showStatus || item.status.toLowerCase() === 'aktif')
  );

  const performanceByYear = dataPerformance.reduce((acc, item) => {
    const year = new Date(item.periode).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});

  const yearList = Object.keys(performanceByYear).sort((a, b) => b - a);
  const [selectYear, setSelectYear] = useState(yearList[0])
  const [selectCategory, setSelectCategory] = useState('hok')

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des']

  const headers = selectCategory === 'nilai'
    ? ['NIK', 'Nama', 'Jabatan', 'Q1', 'Q2', 'Q3', 'Q4', 'Average']
    : ['NIK', 'Nama', 'Jabatan', ...months, 'Total/Average'];

  const selectedYear = performanceByYear[selectYear] || [];

  const groupPerformanceByEmployeeAndMonth = (nik, month) => {
    const performanceData = selectedYear.filter(performance => performance.nik === nik && new Date(performance.periode).getMonth() === month && performance.category.toLowerCase() === selectCategory);
    return performanceData.length > 0
      ? performanceData.reduce((total, performance) => total + Number(performance.value), 0)
      : '-'
  };

  const groupPerformanceForKpiAndDR = (nik, month) => {
    const performanceData = selectedYear.filter(performance => performance.nik === nik && new Date(performance.periode).getMonth() === month && performance.category.toLowerCase() === selectCategory);
    return performanceData.length > 0
      ? performanceData.reduce((total, performance) => total + Number(performance.value), 0)
      : '-'
  };

  const groupPerformanceByEmployeeAndQuarter = (nik, quarter) => {
    const monthRanges = {
      Q1: [0, 1, 2],
      Q2: [3, 4, 5],
      Q3: [6, 7, 8],
      Q4: [9, 10, 11]
    };

    const selectedMonths = monthRanges[quarter];

    const performanceData = selectedYear.filter(performance =>
      performance.nik === nik &&
      selectedMonths.includes(new Date(performance.periode).getMonth()) &&
      performance.category.toLowerCase() === selectCategory
    );

    return performanceData.length > 0
      ? performanceData.reduce((total, performance) => total + Number(performance.value), 0)
      : '-'
  };

  const data = dataEmployeeFiltered.map((data) => {
    if (selectCategory === 'nilai') {
      const quarterlyPerformance = ['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => groupPerformanceByEmployeeAndQuarter(data.nik, quarter));
      const validPerformance = quarterlyPerformance.filter(value => value !== "-");
      const totalValidPerformance = validPerformance.length;
      const averagePerformance =
        totalValidPerformance > 0 ? (
          validPerformance.reduce((total, qTotal) => total + qTotal, 0) / totalValidPerformance).toFixed(2) : "-";
      return [data.nik, data.nama_lengkap, data.jabatan, ...quarterlyPerformance, averagePerformance];
    } else {
      const monthlyPerformance = months.map((_, monthIndex) => groupPerformanceForKpiAndDR(data.nik, monthIndex));
      if (selectCategory === 'kpi' || selectCategory === 'dr') {
        const validPerformances = monthlyPerformance.filter(value => value !== "-" && value >= 0);
        const totalValidPerformance = validPerformances.length;
        const averagePerformance =
          totalValidPerformance > 0 ? (
            validPerformances.reduce((total, monthTotal) => total + monthTotal, 0) / totalValidPerformance).toFixed(2) : "-";
        return [data.nik, data.nama_lengkap, data.jabatan, ...monthlyPerformance, averagePerformance];
      } else {
        const monthlyPerformance = months.map((_, monthIndex) => groupPerformanceByEmployeeAndMonth(data.nik, monthIndex));
        const totalPerformance = monthlyPerformance.reduce((total, monthTotal) => total + monthTotal, 0);
        return [data.nik, data.nama_lengkap, data.jabatan, ...monthlyPerformance, totalPerformance];
      }
    }
  });

  const nameCategoryExportData = (category) => {
    const categoryMap = {
      hok: 'HOK',
      jok: 'JOK',
      tlt: 'Terlambat',
      mkr: 'Mangkir',
      izn: 'Izin',
      skt: 'Sakit',
      plg: 'Pulang Cepat',
      kpi: 'KPI',
      dr: 'Daily Report (B)',
      nilai: 'Nilai Atasan'
    }
    return categoryMap[category]
  }

  const handleExportData = () => {

    if (!selectCategory) {
      alert('Category Penilaian yg akan di Export belum dipilih !')
      return;
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

    XLSX.utils.book_append_sheet(wb, ws, "rekap penilaian");
    XLSX.writeFile(wb, `Rekap Penilaian ${nameCategoryExportData(selectCategory)} Karyawan SN tahun ${selectYear}.xlsx`)

  }

  return (
    <div>
      <NavigationBar logoSN={logoSN} title={title} />
      <div className='m-2 mt-2 animate__animated animate__fadeInDown'>
        <div className='form-check position-fixed end-0 me-5' onChange={() => setShowStatus(!showStatus)}>
          <input className='form-check-input' type='checkbox' defaultChecked={showStatus} />
          <label className='form-check-label text-danger text-decoration-underline'>NON AKTIF</label>
        </div>
        <i className="bi bi-file-earmark-arrow-down position-fixed end-0 me-3 fs-4" style={{ cursor: "pointer" }}
          data-tooltip-id='export-performance-tooltip'
          data-tooltip-content='export ke excel'
          onClick={handleExportData}
        ></i>
        <Tooltip id='export-performance-tooltip' place='bottom' />
        <Tabs defaultActiveKey={yearList[0]}
          className='mt-1 mb-2'
          onSelect={(year) => {
            setSelectYear(year);
            setSelectCategory('');
          }}
        >
          {yearList.map((year) => (
            <Tab eventKey={year} title={year} key={year}>
              <Tabs defaultActiveKey={'hok'} className='mt-1 mb-2'
                onSelect={(category) => setSelectCategory(category.toLocaleLowerCase())}>
                <Tab eventKey={'hok'} title='HOK'>
                  <HOK
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'jok'} title='JOK'>
                  <JOK
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'tlt'} title='Terlambat'>
                  <Late
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'mkr'} title='Mangkir'>
                  <Absent
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'izn'} title='Izin'>
                  <Permission
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'skt'} title='Sakit'>
                  <Sick
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'plg'} title='Pulang Cepat'>
                  <GoHome
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'kpi'} title='KPI'>
                  <KPI
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'dr'} title='Daily Report'>
                  <DR
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'nilai'} title='Nilai Atasan'>
                  <Evaluation
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
              </Tabs>
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default RekapPerformanceSN;
