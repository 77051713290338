import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const FormRekapMasaKerja = ({ formRekapRef }) => {

  const [dataEmployees, setDataEmployees] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_URL}/employees`)
        .then((res) => {
          setDataEmployees(res.data.employee);
        }),
      axios.get(`${process.env.REACT_APP_URL}/users/token`, { withCredentials: true })
        .then((res) => {
          const decoded = jwtDecode(res.data.token);

          const userStatus = decoded.status.toLowerCase();
          axios.get(`${process.env.REACT_APP_URL}/employees`)
            .then((res) => {
              const allEmployees = res.data.employee;

              const filteredEmployees = userStatus === 'direktur ae'
                ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'aditama energi')
                : userStatus === 'direktur bk'
                  ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'bara kumala')
                  : userStatus === 'direktur sn'
                    ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'sinet')
                    : userStatus === 'pm ae'
                      ? allEmployees.filter(emp => (
                        (emp.divisi.toLowerCase() === 'produksi') ||
                        (emp.unit.toLowerCase() === 'aditama energi' &&
                          emp.lokasi_kerja.toLowerCase() === 'site' &&
                          ['umum', 'mine road', 'safety'].includes(emp.divisi.toLowerCase())) ||
                        //aliyani
                        emp.nik === 2212063
                      ))
                      : userStatus === 'om ae'
                        ? allEmployees.filter(emp => (
                          (emp.divisi.toLowerCase() === 'engineering') ||
                          (emp.unit.toLowerCase() === 'aditama energi' && emp.lokasi_kerja.toLowerCase() === 'site' &&
                            ['explorasi', 'perencanaan'].includes(emp.divisi.toLowerCase()))
                        ))
                        : userStatus === 'inspektur logistik'
                          ? allEmployees.filter(emp => (emp.divisi.toLowerCase() === 'logistik') ||
                            //triyoga,aspian & wiwid
                            [2217314, 1112043, 2316232].includes(emp.nik))
                          : userStatus === 'sm bk'
                            ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'bara kumala' && emp.lokasi_kerja.toLowerCase() === 'site')
                            : userStatus === 'koordinator sn'
                              ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'sinet' && emp.lokasi_kerja.toLowerCase() === 'site')
                              : allEmployees;
              setDataEmployees(filteredEmployees);
            });
        })
        .catch((err) => {
          console.log('err', err.message);
          if (err) {
            alert('Anda Belum login atau sesi anda habis, Login dulu ...!');
            window.location.replace('/login');
          }
        })
    ]);
  }, [])

  const dataLabel = [
    { label: 'ADITAMA ENERGI', text: 'info', navigate: 'AE' },
    { label: 'BARA KUMALA', text: 'danger', navigate: 'BK' },
    { label: 'SINET', text: 'success', navigate: 'SN' },
  ]

  return (
    <div className="border border-2 rounded bg-body-secondary shadow-sm w-25 fs-4 p-2 mt-5 me-5 position-fixed end-0 top-0" style={{ zIndex: '100' }} ref={formRekapRef}>
      {dataLabel.map((item, i) => {
        return (
          <div key={i}
            className={`title-h1 ms-2 text-${item.text}`}>
            {`${item.label}`}
            <i className='bi bi-chevron-right ms-5 text-dark'
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/rekapMasaKerja${item.navigate}`, { state: { dataEmployees: dataEmployees } })}>
            </i>
          </div>
        )
      })}
    </div>
  )
}

export default FormRekapMasaKerja