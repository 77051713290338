import React, { useEffect, useRef, useState } from 'react'
import NavigationBar from '../../Components/Utility/Navbar'
import logoAE from '../../Assets/Logo/Aditama.png'
import FilterEmployee from '../../Components/Utility/FilterEmployee'
import { useLocation, useNavigate } from 'react-router-dom'
import { countAge, generationCategory, todayDate } from '../../Components/Utility'
import { Tooltip } from 'react-tooltip';
import * as XLSX from 'xlsx';

const DataEmployeeAE = ({ title }) => {

  const location = useLocation()
  const dataEmployees = location.state.dataEmployees
  const formFilterRef = useRef(null)
  const navigate = useNavigate()

  const filterEmployeeByUnit = (status, unit) => {
    return dataEmployees.filter(employee => {
      const matchStatus = employee.status.toLowerCase() === status.toLowerCase();
      const matchUnit = employee.unit.toLowerCase() === unit.toLowerCase();

      return matchStatus && matchUnit;
    })
  }

  const dataEmployeeAE = filterEmployeeByUnit('aktif', 'aditama energi')

  const [selectedColumns, setSelectedColumns] = useState('')
  const [showFormFilter, setShowFormFilter] = useState(false)

  const formFilter = () => {
    setShowFormFilter(!showFormFilter)
  }

  const handleClickOutSide = (e) => {
    if (formFilterRef.current && !formFilterRef.current.contains(e.target)) {
      setShowFormFilter(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide)
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide)
    }
  })

  const handleRowClick = (nik) => {
    navigate(`/detailEmployee/${nik}`, { state: { from: '/dataEmployee/aditama', dataEmployees: dataEmployees } })
  }

  const handleExportData = () => {
    const headers = ["NIK", "Nama Lengkap", "Jabatan", "Divisi"];
    if (selectedColumns.age) headers.push("Umur");
    if (selectedColumns.level) headers.push("Level");
    if (selectedColumns.workLocation) headers.push("Lokasi Kerja");
    if (selectedColumns.workBond) headers.push("Ikatan Kerja");
    if (selectedColumns.generationAge) headers.push("Generasi");
    if (selectedColumns.gender) headers.push("Jenis Kelamin");
    if (selectedColumns.religion) headers.push("Agama");
    if (selectedColumns.education) headers.push("Pendidikan");
    if (selectedColumns.weddingStatus) headers.push("Status Pernikahan");
    if (selectedColumns.taxStatus) headers.push("Status Pajak");
    if (selectedColumns.noHp) headers.push("No Handphone");
    if (selectedColumns.noBpjsKes) headers.push("No BPJS Kesehatan");
    if (selectedColumns.noBpjsKet) headers.push("No BPJS Ketenagakerjaan");

    const filteredData = dataEmployeeAE.map(employee => {
      let row = [
        employee.nik,
        employee.nama_lengkap,
        employee.jabatan,
        employee.divisi
      ];

      if (selectedColumns.age) row.push(countAge(employee.tanggal_lahir) + " tahun");
      if (selectedColumns.level) row.push(employee.level);
      if (selectedColumns.workLocation) row.push(employee.lokasi_kerja);
      if (selectedColumns.workBond) row.push(employee.ikatan_kerja);
      if (selectedColumns.generationAge) row.push(generationCategory(employee.tanggal_lahir));
      if (selectedColumns.gender) row.push(employee.jenis_kelamin);
      if (selectedColumns.religion) row.push(employee.agama);
      if (selectedColumns.education) row.push(employee.pendidikan);
      if (selectedColumns.weddingStatus) row.push(employee.status_pernikahan);
      if (selectedColumns.taxStatus) row.push(employee.status_pajak);
      if (selectedColumns.noHp) row.push(employee.no_hp);
      if (selectedColumns.noBpjsKes) row.push(employee.no_bpjs_kesehatan);
      if (selectedColumns.noBpjsKet) row.push(employee.no_bpjs_ketenagakerjaan);
      return row;
    })

    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet([headers, ...filteredData]);

    XLSX.utils.book_append_sheet(wb, ws, "data Karyawan");
    XLSX.writeFile(wb, `Data Karyawan AE ${todayDate}.xlsx`)

  }

  return (
    <>
      <NavigationBar logoAE={logoAE} title={title} />
      <i className="bi bi-arrow-left"
        onClick={() => navigate('/dashboard')}
      ></i>
      <div className='container mt-2 animate__animated animate__fadeInDown'>
        <table className='table table-bordered border-dark mt-4'>
          <thead className='table-info table-bordered border-dark sticky-header'>
            <tr className='text-center fs-4'>
              <th>No.</th>
              <th>NIK</th>
              <th>Nama Lengkap</th>
              <th>Jabatan</th>
              <th>Divisi</th>
              {selectedColumns.age && <th>Umur</th>}
              {selectedColumns.level && <th>Level</th>}
              {selectedColumns.workLocation && <th>Lokasi Kerja</th>}
              {selectedColumns.workBond && <th>Ikatan Kerja</th>}
              {selectedColumns.generationAge && <th>Generasi</th>}
              {selectedColumns.gender && <th>Jenis Kelamin</th>}
              {selectedColumns.religion && <th>Agama</th>}
              {selectedColumns.education && <th>Pendidikan</th>}
              {selectedColumns.weddingStatus && <th>Status Pernikahan</th>}
              {selectedColumns.taxStatus && <th>Status Pajak</th>}
              {selectedColumns.noHp && <th>No Handphone</th>}
              {selectedColumns.noBpjsKes && <th>No BPJS Kesehatan</th>}
              {selectedColumns.noBpjsKet && <th>No BPJS Ketenagakerjaan</th>}
            </tr>
          </thead>
          <tbody>
            {dataEmployeeAE.map((employee, i) => (
              <tr key={i}>
                <th className='text-center'>{i + 1}</th>
                <td className='text-center'>{employee.nik}</td>
                <td onClick={() => handleRowClick(employee.nik)} style={{ cursor: "pointer" }}>{employee.nama_lengkap}</td>
                <td>{employee.jabatan}</td>
                <td>{employee.divisi}</td>
                {selectedColumns.age && <td>{countAge(employee.tanggal_lahir)} tahun</td>}
                {selectedColumns.level && <td>{employee.level}</td>}
                {selectedColumns.workLocation && <td>{employee.lokasi_kerja}</td>}
                {selectedColumns.workBond && <td>{employee.ikatan_kerja}</td>}
                {selectedColumns.generationAge && <td>{generationCategory(employee.tanggal_lahir)}</td>}
                {selectedColumns.gender && <td>{employee.jenis_kelamin}</td>}
                {selectedColumns.religion && <td>{employee.agama}</td>}
                {selectedColumns.education && <td>{employee.pendidikan}</td>}
                {selectedColumns.weddingStatus && <td>{employee.status_pernikahan}</td>}
                {selectedColumns.taxStatus && <td>{employee.status_pajak}</td>}
                {selectedColumns.noHp && <td>{employee.no_hp}</td>}
                {selectedColumns.noBpjsKes && <td>{employee.no_bpjs_kesehatan}</td>}
                {selectedColumns.noBpjsKet && <td>{employee.no_bpjs_ketenagakerjaan}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className=''>
        <i className="bi bi-funnel fs-1 btn btn-sm"
          style={{ position: "fixed", top: "75px", right: "30px" }}
          data-tooltip-id='filter-dataEmployee-tooltip'
          data-tooltip-content='Filter Data'
          onClick={formFilter}></i>
        {showFormFilter && (
          <FilterEmployee selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} formFilterRef={formFilterRef} />
        )
        }
        <i className="bi bi-file-earmark-arrow-down fs-1 btn btn-sm"
          style={{ position: "fixed", top: "140px", right: "30px" }}
          data-tooltip-id='export-dataEmployee-tooltip'
          data-tooltip-content="Export Ke Excel"
          onClick={handleExportData}
        ></i>
        <Tooltip id='export-dataEmployee-tooltip' />
        <Tooltip id='filter-dataEmployee-tooltip' place='right' />
      </div>
    </>
  )
}

export default DataEmployeeAE