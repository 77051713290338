import React, { useEffect, useState, useRef } from 'react'
import { changeDurationMonth } from '../../Components/Utility';
import { Tooltip } from 'react-tooltip';
import FormRekapMasaKerja from './Rekap/index';

const MasaKerja = ({ employeeAktive }) => {

  const today = new Date();
  let maxYears = 0;
  const rekapMasaKerja = {};

  employeeAktive.forEach((data) => {
    let durasiKerja = 0;

    if (data.ikatan_kerja) {
      const ikatanKerja = data.ikatan_kerja.toLowerCase();
      const lokasi = data.lokasi_kerja || "UNKNOWN";

      if ((ikatanKerja === 'tetap' || ikatanKerja === 'harian') || (data.nik === 1216229 || data.nik === 1216231)) {
        durasiKerja = (today - new Date(data.awal_pkwt_1)) / changeDurationMonth / 12;
      } else {
        const durations = [
          { start: data.awal_pkwt_1, end: data.akhir_pkwt_1 },
          { start: data.akhir_pkwt_1, end: data.akhir_pPkwt_1 },
          { start: data.awal_pkwt_2, end: data.akhir_pkwt_2 },
          { start: data.akhir_pkwt_2, end: data.akhir_pPkwt_2 },
          { start: data.awal_pkwt_3, end: data.akhir_pkwt_3 },
          { start: data.akhir_pkwt_3, end: data.akhir_pPkwt_3 },
          { start: data.awal_pkwt_4, end: data.akhir_pkwt_4 },
          { start: data.awal_pkwt_5, end: data.akhir_pkwt_5 },
          { start: data.awal_pkwt_6, end: data.akhir_pkwt_6 },
          { start: data.awal_pkwt_7, end: data.akhir_pkwt_7 },
          { start: data.awal_pkwt_8, end: data.akhir_pkwt_8 },
          { start: data.awal_pkwt_9, end: data.akhir_pkwt_9 },
          { start: data.awal_pkwt_10, end: data.akhir_pkwt_10 },
          { start: data.awal_pkwt_11, end: data.akhir_pkwt_11 },
          { start: data.awal_pkwt_12, end: data.akhir_pkwt_12 },
        ];

        durasiKerja = durations.reduce((sum, { start, end }) => {
          if (!start || !end || start === "0000-00-00" || end === "0000-00-00") {
            return sum;
          }
          const startDate = new Date(start);
          const endDate = new Date(end);

          if (isNaN(startDate) || isNaN(endDate)) {
            return sum;
          };

          let durasi = (endDate - startDate) / changeDurationMonth;

          if (endDate > today) {
            durasi -= (endDate - today) / changeDurationMonth;
          };

          return sum + durasi / 12;

        }, 0);
      }

      const tahunKerja = Math.floor(durasiKerja);
      maxYears = Math.max(maxYears, tahunKerja);

      if (!rekapMasaKerja[tahunKerja]) {
        rekapMasaKerja[tahunKerja] = {
          PKWT: { SITE: 0, SMD: 0 },
          PKWTT: { SITE: 0, SMD: 0 },
          Harian: { SITE: 0, SMD: 0 },
        };
      }

      if (ikatanKerja === "tetap") {
        lokasi.includes("SITE") ? rekapMasaKerja[tahunKerja].PKWTT.SITE++ : rekapMasaKerja[tahunKerja].PKWTT.SMD++;
      } else if (ikatanKerja === "harian") {
        lokasi.includes("SITE") ? rekapMasaKerja[tahunKerja].Harian.SITE++ : rekapMasaKerja[tahunKerja].Harian.SMD++;
      } else {
        lokasi.includes("SITE") ? rekapMasaKerja[tahunKerja].PKWT.SITE++ : rekapMasaKerja[tahunKerja].PKWT.SMD++;
      }
    }
  });

  const [showFormRekap, setShowFormRekap] = useState(false);
  const formRekapRef = useRef(null);

  const handleClickOutSide = (e) => {
    if (formRekapRef.current && !formRekapRef.current.contains(e.target)) {
      setShowFormRekap(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);

    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    }
  }, [])

  return (
    <div className='container mt-4 animate__animated animate__fadeInRight'>
      <div className='d-flex justify-content-between'>
        <div className='title-h1 fs-3 mb-2'>Rekap Masa Kerja</div>
        <div className='fs-1 text-primary' style={{ cursor: 'pointer' }}
          onClick={() => setShowFormRekap(!showFormRekap)}
          data-tooltip-id='rekap-masaKerja-tooltip' data-tooltip-content="Rekap Masa Kerja"
        ><i className="bi bi-justify"></i></div>
      </div>
      <Tooltip id='rekap-masaKerja-tooltip' place='left' />
      {showFormRekap && (
        <FormRekapMasaKerja formRekapRef={formRekapRef} />
      )}
      <table className='table table-bordered border-dark shadow shadow-sm'>
        <thead className='table border-dark table-secondary'>
          <tr className="text-center" style={{ verticalAlign: 'middle' }}>
            <th rowSpan={2}>No.</th>
            <th rowSpan={2}>Masa Kerja</th>
            <th colSpan={3}>Kontrak (PKWT)</th>
            <th colSpan={3}>Tetap (PKWTT)</th>
            <th colSpan={3}>Harian</th>
            <th rowSpan={2}>GRAND TOTAL</th>
          </tr>
          <tr className="text-center">
            <th>SITE</th>
            <th>SMD</th>
            <th>TOTAL</th>
            <th>SITE</th>
            <th>SMD</th>
            <th>TOTAL</th>
            <th>SITE</th>
            <th>SMD</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: maxYears + 1 }, (_, i) => {
            const data = rekapMasaKerja[i] || {
              PKWT: { SITE: 0, SMD: 0 },
              PKWTT: { SITE: 0, SMD: 0 },
              Harian: { SITE: 0, SMD: 0 },
            };

            return (
              <tr key={i} className="text-center">
                <td className='fw-bold'>{i + 1}</td>
                <td className='fw-bold'>{`< ${i + 1} Tahun`}</td>
                <td>{data.PKWT.SITE}</td>
                <td>{data.PKWT.SMD}</td>
                <td>{data.PKWT.SITE + data.PKWT.SMD}</td>
                <td>{data.PKWTT.SITE}</td>
                <td>{data.PKWTT.SMD}</td>
                <td>{data.PKWTT.SITE + data.PKWTT.SMD}</td>
                <td>{data.Harian.SITE}</td>
                <td>{data.Harian.SMD}</td>
                <td>{data.Harian.SITE + data.Harian.SMD}</td>
                <td className='fw-bold'>{data.PKWT.SITE + data.PKWT.SMD + data.PKWTT.SITE + data.PKWTT.SMD + data.Harian.SITE + data.Harian.SMD}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default MasaKerja;
