import React from "react"
import { useLocation } from "react-router-dom";
import logoSN from "../../Assets/Logo/SINET.png"
import NavigationBar from "../../Components/Utility/Navbar"
import { changeDurationMonth, dateFormated } from './../../Components/Utility/index';
import { Tooltip } from "react-tooltip";
import * as XLSX from 'xlsx';

const EndPkwtSN = ({ title }) => {

  const location = useLocation()
  const dataEndPkwt = location.state.selectedDateEndPkwt
  const filterDataUnit = dataEndPkwt.filter(data => data.unit && data.unit.toLowerCase() === 'sinet')

  const headers = [
    "NIK", "Nama", "Jabatan", "No PKWT", "Awal Kontrak", "Akhir Kontrak", "Durasi"
  ]

  const handleExportData = () => {
    const wb = XLSX.utils.book_new();
    const data = filterDataUnit.map(item => {
      const durasi = Math.round((new Date(item.date) - new Date(item.dateStart)) / changeDurationMonth)
      return (
        [
          item.nik,
          item.name,
          item.position,
          item.no_pkwt,
          item.dateStart,
          item.date,
          `${durasi} bulan`
        ]
      )
    });
    const fileDate = filterDataUnit[0].date.replace(/-/g, '');

    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

    XLSX.utils.book_append_sheet(wb, ws, "data habis kontrak");
    XLSX.writeFile(wb, `Data Karyawan SN Habis Kontrak ${fileDate}.xlsx`)
  }

  return (
    <>
      <NavigationBar logoSN={logoSN} title={title} />
      <div className="container ">
        <table className="table table-bordered border-dark mt-4 animate__animated animate__fadeInDown">
          <thead className="table-success table-bordered border-dark sticky-header">
            <tr className="text-center fs-4">
              <th>No.</th>
              <th>NIK</th>
              <th>Nama</th>
              <th>Jabatan</th>
              <th>No PKWT</th>
              <th colSpan={2}>Tanggal</th>
              <th>Durasi</th>
            </tr>
          </thead>
          <tbody>
            {filterDataUnit.map((data, i) => {
              const duration = (new Date(data.date) - new Date(data.dateStart)) / changeDurationMonth
              return (
                <tr key={i}>
                  <th className='text-center'>{i + 1}.</th>
                  <th className='text-center'>{data.nik}</th>
                  <th >{data.name}</th>
                  <th >{data.position}</th>
                  <th className='text-center'>{data.no_pkwt}</th>
                  <th className='text-center'>{dateFormated(data.dateStart)}</th>
                  <th className='text-center'>{dateFormated(data.date)}</th>
                  <th className='text-center'>{Math.round(duration)} Bulan</th>
                </tr>
              )
            })}
          </tbody>
        </table>
        <i className="bi bi-file-earmark-arrow-down fs-1 btn btn-sm"
          style={{ position: "fixed", top: "60px", right: "30px" }}
          data-tooltip-id='export-endPkwt-tooltip'
          data-tooltip-content="Export Ke Excel"
          onClick={handleExportData}
        ></i>
        <Tooltip id="export-endPkwt-tooltip" />
      </div >
    </>
  )
}

export default EndPkwtSN