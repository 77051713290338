import React from 'react'
import NavigationBar from '../../../Components/Utility/Navbar';
import logoBK from '../../../Assets/Logo/BK.png'
import { useLocation } from 'react-router-dom';
import { changeDurationMonth, todayDate } from '../../../Components/Utility';
import { Tooltip } from 'react-tooltip';
import * as XLSX from 'xlsx';

const RekapMasaKerjaBK = ({ title }) => {

  const location = useLocation();
  const dataEmployees = location.state.dataEmployees;

  const dataEmployee = dataEmployees.filter((data) =>
    data.unit.toLowerCase() === 'bara kumala' &&
    (data.status.toLowerCase() === 'aktif')
  );

  const updatedDataEmployee = dataEmployee.map((data) => {
    const today = new Date();
    let workDurations = 0;

    if (data.ikatan_kerja) {
      const ikatanKerja = data.ikatan_kerja.toLowerCase();

      if ((ikatanKerja === 'tetap' || ikatanKerja === 'harian') || (data.nik === 1216229 || data.nik === 1216231)) {
        workDurations = (today - new Date(data.awal_pkwt_1)) / changeDurationMonth;
      } else {
        const durations = [
          { start: data.awal_pkwt_1, end: data.akhir_pkwt_1 },
          { start: data.akhir_pkwt_1, end: data.akhir_pPkwt_1 },
          { start: data.awal_pkwt_2, end: data.akhir_pkwt_2 },
          { start: data.akhir_pkwt_2, end: data.akhir_pPkwt_2 },
          { start: data.awal_pkwt_3, end: data.akhir_pkwt_3 },
          { start: data.akhir_pkwt_3, end: data.akhir_pPkwt_3 },
          { start: data.awal_pkwt_4, end: data.akhir_pkwt_4 },
          { start: data.awal_pkwt_5, end: data.akhir_pkwt_5 },
          { start: data.awal_pkwt_6, end: data.akhir_pkwt_6 },
          { start: data.awal_pkwt_7, end: data.akhir_pkwt_7 },
          { start: data.awal_pkwt_8, end: data.akhir_pkwt_8 },
          { start: data.awal_pkwt_9, end: data.akhir_pkwt_9 },
          { start: data.awal_pkwt_10, end: data.akhir_pkwt_10 },
          { start: data.awal_pkwt_11, end: data.akhir_pkwt_11 },
          { start: data.awal_pkwt_12, end: data.akhir_pkwt_12 },
        ];

        workDurations = durations.reduce((sum, { start, end }) => {
          if (!start || !end || start === "0000-00-00" || end === "0000-00-00") {
            return sum;
          }
          const startDate = new Date(start);
          const endDate = new Date(end);

          if (isNaN(startDate) || isNaN(endDate)) {
            return sum;
          }

          let durasi = (endDate - startDate) / changeDurationMonth;

          if (endDate > today) {
            durasi -= (endDate - today) / changeDurationMonth;
          }

          return sum + durasi;
        }, 0);
      }
    };

    const totalMount = Math.floor(workDurations);
    const year = Math.floor(totalMount / 12);
    const month = totalMount % 12;

    let formattedDurations = '';
    if (year > 0) {
      formattedDurations += `${year} tahun `;
      if (month > 0) {
        formattedDurations += `${month} bulan`;
      }
    } else {
      formattedDurations += `${month} bulan`;
    };

    return {
      ...data,
      workDuration: formattedDurations,
    };
  });


  const dataLabel = [
    { label: 'NO.' },
    { label: 'NIK' },
    { label: 'Nama' },
    { label: 'Jabatan' },
    { label: 'Divisi' },
    { label: 'Status' },
    { label: 'Masa Kerja' },
  ];

  const headers = dataLabel.filter((item) => item.label !== "NO.").map((item) => item.label);

  const data = updatedDataEmployee.map((data) => [
    data.nik,
    data.nama_lengkap,
    data.jabatan,
    data.divisi,
    data.ikatan_kerja,
    data.workDuration
  ]);

  const handleExportData = () => {

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

    XLSX.utils.book_append_sheet(wb, ws, "Rekap Masa Kerja");
    XLSX.writeFile(wb, `Rekap Masa Kerja Karyawan BK ${todayDate}.xlsx`)
  };

  return (
    <>
      <NavigationBar title={title} logoBK={logoBK} />
      <i className="bi bi-file-earmark-arrow-down position-fixed end-0 me-5 fs-1 mt-5" style={{ cursor: "pointer" }}
        data-tooltip-id='export-pkwt-tooltip'
        data-tooltip-content='export ke excel'
        onClick={handleExportData}
      ></i>
      <Tooltip id='export-pkwt-tooltip' place='bottom' />
      <div className='container mt-4 animate__animated animate__fadeInDown'>
        <table className='table table-bordered border-dark shadow shadow-sm'>
          <thead className='table border-dark table-danger'>
            <tr className='text-center fs-5'>
              {dataLabel.map((item, i) => {
                return (
                  <th key={i}>{item.label}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {updatedDataEmployee.map((employee, i) => (
              <tr key={i} className='fw-bold'>
                <td className='text-center'>{i + 1}.</td>
                <td className='text-center'>{employee.nik}</td>
                <td>{employee.nama_lengkap}</td>
                <td>{employee.jabatan}</td>
                <td>{employee.divisi}</td>
                <td>{employee.ikatan_kerja}</td>
                <td className='text-center'>{employee.workDuration}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default RekapMasaKerjaBK;