import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import ReactDOMServer from 'react-dom/server'
import { dateFormated, Loaders } from '../Utility'

const ListKPI = ({ isAdmin, username }) => {
  const [dataEmployees, setDataEmployees] = useState([]);
  const [dataListKPI, setDataListKPI] = useState([]);
  const [dataPerformance, setDataPerformance] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [workLocation, setWorkLocation] = useState('SMD');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  console.log(loading)

  useEffect(() => {
    Promise.all([
      axios
        .get(`${process.env.REACT_APP_URL}/employees`)
        .then((res) => setDataEmployees(res.data.employee)),
      axios
        .get(`${process.env.REACT_APP_URL}/listKPI`)
        .then((res) => setDataListKPI(res.data.dataListKPI)),
      axios
        .get(`${process.env.REACT_APP_URL}/performance`)
        .then((res) => {
          setDataPerformance(res.data.dataPerformance)
          setLoading(false)
        }),
    ]).catch((err) => {
      console.log('err', err.message);
    });
  }, []);

  const filterEmployeesByCriteria = (unit, nama_lengkap, status, lokasi_kerja) => {
    return dataEmployees.filter((employee) => {
      const matchUnit = unit ? employee.unit.toLowerCase() === unit.toLowerCase() : true;
      const matchWorkLocation = lokasi_kerja
        ? employee.lokasi_kerja.toLowerCase() === lokasi_kerja.toLowerCase()
        : true;
      const matchName = nama_lengkap
        ? employee.nama_lengkap.toLowerCase().includes(nama_lengkap.toLowerCase())
        : true;
      const matchStatus = status ? true : employee.status.toLowerCase() === 'aktif';
      return matchUnit && matchWorkLocation && matchName && matchStatus;
    });
  };

  const filteredEmployee = filterEmployeesByCriteria(
    selectedUnit,
    searchName,
    showStatus,
    workLocation
  );

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des'];
  const currentYear = '2025';
  const periods = months.map((_, index) => `${currentYear}-${(index + 1).toString().padStart(2, '0')}-01`);
  const dataTable = ['No.', 'NIK', 'Nama', 'Unit', ...months];

  const getPerformanceKPI = dataPerformance.filter(kpi => kpi.category === 'kpi')

  const getKPIForEmployee = (nik, period) => {
    const kpiData = dataListKPI.find((kpi) => kpi.nik === nik && kpi.periode === period);
    const kpiValue = kpiData ? kpiData.listKPI : null;
    const performanceKpi = getPerformanceKPI.find((data) => data.nik === nik && data.periode === period);
    const performanceValue = performanceKpi ? performanceKpi.value : null;

    if (kpiValue === 'Belum') return <i className="bi bi-x-lg text-danger fw-bold fs-5"></i>;
    if (performanceValue) return <div className='text-center fw-bold'>{performanceValue}</div>
    if (kpiValue) return <i className="bi bi-check-lg text-primary fw-bold fs-5"></i>;
    return '-';
  };


  const getKPITotalsByLocation = (location, period) => {
    return dataListKPI.filter((kpi) => {
      const employee = dataEmployees.find((emp) => emp.nik === kpi.nik && emp.lokasi_kerja === location);
      return employee && kpi.periode === period && kpi.listKPI !== 'Belum';
    }).length;
  };

  const monthlyKPITotalsByLocation = months.map((_, index) => {
    const period = periods[index];
    return {
      site: getKPITotalsByLocation('SITE', period),
      smd: getKPITotalsByLocation('SMD', period),
    };
  });

  return (
    <div className="container">
      {loading ? (
        <Loaders />
      ) : (
        <>
          <div className="title-h1 fs-1">List KPI</div>
          <div className="m-4 d-flex justify-content-between align-items-center bg-body-secondary p-4 rounded border border-dark animate__animated animate__fadeInDown">
            <div className="d-flex flex-column">
              <label className="title-h1 ms-1 fs-5">Unit</label>
              <select className="form-select" onChange={(e) => setSelectedUnit(e.target.value)}>
                <option value="">Semua</option>
                <option value="ADITAMA ENERGI">Aditama Energi</option>
                <option value="BARA KUMALA">Bara Kumala</option>
                <option value="SINET">SINET</option>
              </select>
            </div>
            <div className="d-flex flex-column">
              <label className="title-h1 ms-1 fs-5">Lokasi Kerja</label>
              <select className="form-select" onChange={(e) => setWorkLocation(e.target.value)} value={workLocation}>
                <option value="">Semua</option>
                <option value="SMD">SMD</option>
                <option value="SITE">SITE</option>
              </select>
            </div>
            <div className="form-check " onChange={() => setShowStatus(!showStatus)}>
              <input type="checkbox" className="form-check-input" defaultChecked={showStatus} />
              <label className="form-check-label text-decoration-underline fw-bold text-danger">NON AKTIF</label>
            </div>
            <input
              type="text"
              className="input-underlined w-25"
              placeholder="Cari Nama Karyawan...."
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>
          <div className="detail-employee">
            {isAdmin && (
              <i
                className="bi bi-plus-lg fs-1"
                onClick={() =>
                  navigate('/inputListKPI', { state: { dataEmployees: dataEmployees, username: username } })
                }
                data-tooltip-id="input-listKPI-tooltip"
                data-tooltip-content="input list kpi"
              ></i>
            )}
            <Tooltip id="input-listKPI-tooltip" place="left" />
          </div>
          <div className="">
            {isAdmin && (
              <i
                className="bi bi bi-pencil-square fs-1"
                onClick={() =>
                  navigate('/updateListKPI', { state: { dataListKPI: dataListKPI, username: username } })
                }
                data-tooltip-id="edit-listKPI-tooltip"
                data-tooltip-content="edit list kpi"
              ></i>
            )}
            <Tooltip id="edit-listKPI-tooltip" place="left" />
          </div>
          <table className="table table-bordered border-dark m-4 animate__animated animate__fadeInDown z-n1">
            <thead className="table-secondary table-bordered border-dark">
              <tr>
                <th rowSpan={2} className="text-center fw-bold fs-5">Lokasi Kerja</th>
                <th colSpan={months.length} className="text-center fw-bold fs-5">Sudah Terkumpul</th>
              </tr>
              <tr>
                {months.map((month, i) => (
                  <th key={i} className="text-center fw-bold fs-5">{month}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center fw-bold">SITE</td>
                {monthlyKPITotalsByLocation.map((total, i) => (
                  <td key={i} className="text-center fw-bold">{total.site > 0 ? total.site : '-'}</td>
                ))}
              </tr>
              <tr>
                <td className="text-center fw-bold">SMD</td>
                {monthlyKPITotalsByLocation.map((total, i) => (
                  <td key={i} className="text-center fw-bold">{total.smd > 0 ? total.smd : '-'}</td>
                ))}
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered border-dark m-4 animate__animated animate__fadeInDown z-n1">
            <thead className="table-info table-bordered border-dark sticky-header">
              <tr>
                {dataTable.map((table, i) => (
                  <th key={i} className="text-center fw-bold fs-5">
                    {table}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEmployee.map((item, j) => (
                <tr key={j}>
                  <td className="text-center fw-bold">{j + 1}.</td>
                  <td className="text-center">{item.nik}</td>
                  <td>{item.nama_lengkap}</td>
                  <td className="text-center">{item.unit}</td>
                  {periods.map((period, index) => {
                    const filteredKPI = dataListKPI
                      .filter((kpi) => kpi.nik === item.nik && kpi.periode === period)
                      .filter((kpi) => kpi.date !== null && kpi.date !== '' && kpi.listKPI);

                    return (
                      <td
                        key={index}
                        className='text-center'
                        data-tooltip-id={filteredKPI.length > 0 ? `tooltip-${item.nik}-${period}` : null}
                        data-tooltip-html={
                          filteredKPI.length > 0
                            ? ReactDOMServer.renderToString(
                              <>
                                {filteredKPI.map((kpi, k) => (
                                  <div key={k}>Dibuat dan disetujui
                                    <br />
                                    ({dateFormated(kpi.date)})</div>
                                ))}
                              </>
                            )
                            : null
                        }
                      >
                        {getKPIForEmployee(item.nik, period)}
                        {filteredKPI.length > 0 &&
                          <Tooltip id={`tooltip-${item.nik}-${period}`} place="top"
                            className='tooltip-listKPI'
                          />}
                      </td>
                    );
                  })}
                </tr>
              ))
              }
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ListKPI;
