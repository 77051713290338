import React from 'react'
import { useLocation } from 'react-router-dom'
import logoBK from '../../Assets/Logo/BK.png'
import NavigationBar from '../../Components/Utility/Navbar'
import { getRemainingTimeLicense } from '../../Components/Utility'
import { Tooltip } from 'react-tooltip'
import * as XLSX from 'xlsx';

const RekapLicenseBK = ({ title }) => {

  const location = useLocation()
  const dataEmployees = location.state.dataEmployees
  const dataDrivingLicense = location.state.dataDrivingLicense

  const dataEmployee = dataEmployees.filter(item => item.unit.toLowerCase() === 'bara kumala' && item.status.toLowerCase() === 'aktif')
  const dataLicense = dataDrivingLicense.filter(item => item.unit.toLowerCase() === 'bara kumala')

  const dataCombined = dataEmployee.map(employee => {
    const license = dataLicense.find(license => license.nik === employee.nik)
    return license ? { ...employee, ...license } : null
  }).filter(item => item !== null)

  const dataLabel = [
    'No.', 'NIK', 'Nama', 'Jabatan', 'No SIM', 'Jenis', 'Berlaku s/d', 'Wilayah', 'Sisa'
  ]
  const headers = [
    'NIK', 'Nama', 'Jabatan', 'No SIM', 'Jenis', 'Berlaku s/d', 'Wilayah', 'Sisa'
  ]

  const dateFormated = (date) => {
    if (!date) return '-'
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    return new Date(date).toLocaleDateString('id-ID', options)
  }

  const data = dataCombined.map((data) => {
    const remaining = getRemainingTimeLicense(data.date_expired)
    return [
      data.nik, data.nama_lengkap, data.jabatan, data.no_license, data.type, data.date_expired, data.region, remaining
    ]
  })

  const handleExportData = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

    XLSX.utils.book_append_sheet(wb, ws, "rekap SIM");
    XLSX.writeFile(wb, `Rekap SIM Karyawan BK.xlsx`)
  }

  return (
    <>
      <NavigationBar logoBK={logoBK} title={title} />
      <i className="bi bi-file-earmark-arrow-down position-fixed end-0 me-3 mt-2 fs-4" style={{ cursor: "pointer" }}
        data-tooltip-id='export-drivingLicense-tooltip'
        data-tooltip-content='export ke excel'
        onClick={handleExportData}
      ></i>
      <Tooltip id='export-drivingLicense-tooltip' place='bottom' />
      <div className='mt-2 ms-3 me-3 mt-5 animate__animated animate__fadeInDown'>
        <table className='table table-bordered border-dark mt-3'>
          <thead className='table-danger table-bordered border-dark sticky-header'>
            <tr className='text-center'>
              {dataLabel.map((label, i) => (
                <th key={i}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataCombined.map((item, j) => (
              <tr key={j}>
                <td className='text-center'>{j + 1}.</td>
                <td>{item.nik}</td>
                <td>{item.nama_lengkap}</td>
                <td>{item.jabatan}</td>
                <td>{item.no_license || '-'}</td>
                <td>{item.type || '-'}</td>
                <td>{dateFormated(item.date_expired) || '-'}</td>
                <td>{item.region || '-'}</td>
                <td>{getRemainingTimeLicense(item.date_expired)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default RekapLicenseBK
