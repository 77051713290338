import React, { useEffect, useState } from 'react'
import EmployeesDashboard from './EmployeesDashboard'
import PkwtDashboard from './PkwtDashboard'
import PerformanceDashboard from './PerformanceDashboard'
import axios from 'axios'
import NavigationBar from '../Utility/Navbar'
import VacationDashboard from './VacationDashboard'
import LicenseDashboard from './LicenseDashboard'
import { jwtDecode } from 'jwt-decode';
import { Loaders } from '../Utility';
import Reminder from './Reminder';
import ListKPI from './ListKPI';

const Dashboard = () => {

  const [activeMenu, setActiveMenu] = useState('dataEmployee')
  const [dataEmployees, setDataEmployees] = useState([])
  const [username, setUsername] = useState('')
  const [isAdmin, setIsAdmin] = useState('')
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_URL}/employees`)
        .then((res) => {
          setDataEmployees(res.data.employee);
        }),
      axios.get(`${process.env.REACT_APP_URL}/users/token`, { withCredentials: true })
        .then((res) => {
          const decoded = jwtDecode(res.data.token);
          setUsername(decoded.username);
          setIsAdmin(decoded.status.toLowerCase() === 'admin');

          const userStatus = decoded.status.toLowerCase();
          axios.get(`${process.env.REACT_APP_URL}/employees`)
            .then((res) => {
              const allEmployees = res.data.employee;

              const filteredEmployees = userStatus === 'direktur ae'
                ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'aditama energi')
                : userStatus === 'direktur bk'
                  ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'bara kumala')
                  : userStatus === 'direktur sn'
                    ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'sinet')
                    : userStatus === 'pm ae'
                      ? allEmployees.filter(emp => (
                        (emp.divisi.toLowerCase() === 'produksi') ||
                        (emp.unit.toLowerCase() === 'aditama energi' &&
                          emp.lokasi_kerja.toLowerCase() === 'site' &&
                          ['umum', 'mine road', 'safety'].includes(emp.divisi.toLowerCase())) ||
                        //aliyani
                        emp.nik === 2212063
                      ))
                      : userStatus === 'om ae'
                        ? allEmployees.filter(emp => (
                          (emp.divisi.toLowerCase() === 'engineering') ||
                          (emp.unit.toLowerCase() === 'aditama energi' && emp.lokasi_kerja.toLowerCase() === 'site' &&
                            ['explorasi', 'perencanaan'].includes(emp.divisi.toLowerCase()))
                        ))
                        : userStatus === 'inspektur logistik'
                          ? allEmployees.filter(emp => (emp.divisi.toLowerCase() === 'logistik') ||
                            //triyoga,aspian & wiwid
                            [2217314, 1112043, 2316232].includes(emp.nik))
                          : userStatus === 'sm bk'
                            ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'bara kumala' && emp.lokasi_kerja.toLowerCase() === 'site')
                            : userStatus === 'koordinator sn'
                              ? allEmployees.filter(emp => emp.unit.toLowerCase() === 'sinet' && emp.lokasi_kerja.toLowerCase() === 'site')
                              : allEmployees;
              setDataEmployees(filteredEmployees);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log('err', err.message);
          if (err) {
            alert('Anda Belum login atau sesi anda habis, Login dulu ...!');
            window.location.replace('/login');
          }
        })
    ]);
  }, [])

  const renderContent = () => {
    switch (activeMenu) {
      case 'dataEmployee': return <EmployeesDashboard dataEmployees={dataEmployees} />
      case 'dataPkwt': return <PkwtDashboard dataEmployees={dataEmployees} />
      case 'dataPerformance': return <PerformanceDashboard dataEmployees={dataEmployees} isAdmin={isAdmin} username={username} />
      case 'dataVacation': return <VacationDashboard dataEmployees={dataEmployees} />
      case 'dataLicense': return <LicenseDashboard dataEmployees={dataEmployees} />
      case 'dataReminder': return <Reminder isAdmin={isAdmin} />
      case 'dataListKPI': return <ListKPI isAdmin={isAdmin} username={username} />
      default: return <EmployeesDashboard />
    }
  }

  return (
    <>
      <NavigationBar username={username} />
      <div className='d-flex'>
        <div className='bg-body-secondary p-2 d-flex flex-column gap-1 justify-content-center align-items-center' style={{ width: "20%", height: "100vh", position: 'fixed' }}>
          <div className={`menu-sidebar animate__animated animate__flipInX ${activeMenu === 'dataEmployee' ? 'active-menu' : ''}`} onClick={() => setActiveMenu('dataEmployee')}>Data Karyawan</div>
          <div className={`menu-sidebar animate__animated animate__flipInX ${activeMenu === 'dataPkwt' ? 'active-menu' : ''}`} onClick={() => setActiveMenu('dataPkwt')}>Masa Kerja</div>
          <div className={`menu-sidebar animate__animated animate__flipInX ${activeMenu === 'dataPerformance' ? 'active-menu' : ''}`} onClick={() => setActiveMenu('dataPerformance')}>Kinerja Karyawan</div>
          <div className={`menu-sidebar animate__animated animate__flipInX ${activeMenu === 'dataVacation' ? 'active-menu' : ''}`} onClick={() => setActiveMenu('dataVacation')}>Cuti Karyawan</div>
          <div className={`menu-sidebar animate__animated animate__flipInX ${activeMenu === 'dataLicense' ? 'active-menu' : ''}`} onClick={() => setActiveMenu('dataLicense')}>SIM Polisi</div>
          <div className={`menu-sidebar animate__animated animate__flipInX ${activeMenu === 'dataReminder' ? 'active-menu' : ''}`} onClick={() => setActiveMenu('dataReminder')}>Reminder</div>
          <div className={`menu-sidebar animate__animated animate__flipInX ${activeMenu === 'dataListKPI' ? 'active-menu' : ''}`} onClick={() => setActiveMenu('dataListKPI')}>List KPI</div>
        </div>
        <div style={{ width: "80%", position: 'relative', left: '270px' }}>
          {loading ? (
            <Loaders />
          ) : (
            renderContent()
          )}
        </div>
      </div>
    </>
  )
}

export default Dashboard